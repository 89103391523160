* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

img, video, svg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.logo {
        object-fit: contain;
    }
}

