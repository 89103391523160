body.dach {
    .down {
        display: none;
    }

    header {
        border-bottom: none;
        position: relative;

        .container {
            position: fixed;
            transform: translateX(-50%);
            left: 50%;
            z-index: 101;

            .menu {
                z-index: 101;
                position: relative;
                transform: translateX(-100%);
                right: -100%;

                background: $primary;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                cursor: pointer;

                .material-icons {
                    font-size: 2rem;
                }
            }
        }

        .main-nav {
            transform: translateX(200vw);
            transition: all 500ms ease-in-out;


            &.active {
                transform: translateX(0);
            }

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            position: fixed;
            background: $primary;
            z-index: 102;
            padding: 1.5rem;


            header {
                border-bottom: 1px solid #fff;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 1rem 0;
                margin: 0 0 1rem 0;

                img {
                    max-width: 200px;
                    object-fit: contain;
                }

                .material-icons {
                    color: #fff;
                    font-size: 2rem;
                }
            }

            ul {
                list-style-type: none;

                li {
                    margin: 1rem 0;


                    a {
                        text-decoration: none;
                        text-transform: uppercase;
                        color: #fff;
                        justify-content: space-evenly;
                        font-size: 35px;
                        font-weight: bold;
                        opacity: .6;
                    }
                }
            }

            footer {
                ul.sub-nav {
                    position: relative;
                    bottom: 2rem;

                    li {
                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    footer {
        .container {
            border-bottom: none;
        }

        img {
            object-fit: contain;
            max-width: 300px;
        }

        .top {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .row {
            border-bottom: 1px solid $font-color;
        }

        .marke {
            opacity: .6;
            font-size: 15px;
            border-bottom: none;
        }

        .praxis-nav {
            height: 100%;
        }


        ul {
            list-style-type: none;

            li a {
                text-transform: uppercase;
                font-size: 15px;
                color: #fff;
                opacity: .6;
            }
        }

    }

    section.header {
        .icon {
            display: none;
        }

        .row.lg.logo {
            display: none;
        }
    }

    .standorte {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        color: #fff;
        padding: 0;
        z-index: 102;


        .standorte-header, .standorte-content .standorte-header {

            background: $primary-dark;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            p {
                margin: 0;
            }

            .material-icons {
                margin-left: .5rem;
            }
        }

        .standorte-content {
            position: fixed;
            bottom: 0;
            left: 0;
            background: $primary;
            transition: all 1s ease-in-out;
            transform: translateY(100vh);
            width: 100vw;

            &.active {
                transform: translateY(0);
            }

            nav {
                padding: 1rem 2rem;

                ul {
                    list-style-type: none;

                    li {
                        margin: .25rem 0;

                        &.logo {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 1rem;

                            .active-item-dot {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                margin-left: 15px;
                                display: block;
                                background: $secondary;
                            }

                            img {
                                max-width: 250px;
                            }
                        }


                        opacity: .6;

                        &.active {
                            opacity: 1;
                        }

                        a {
                            display: block;
                            text-transform: uppercase;
                            color: #fff;
                            padding: .75rem 0;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    #standorte {
        .karte {
            display: none;
        }
    }

    #kompetenzen {
        .row div {
            div {
                padding: 1rem;
            }
        }

        img {
            padding: 1rem;
            padding-left: 0;
            width: 150px;
            height: 150px;
        }

        font-size: 26px;
        font-weight: bold;
    }
}

@include media-breakpoint-down(lg) {

    .standorte-header .container-lg {
        width: auto;
    }
    .content + div {
        @include make-container();
    }
}

@include media-breakpoint-up(lg) {
    body.dach {
        .down {
            display: block;
        }

        .standorte {
            border-bottom: 10px solid $primary;

            > .standorte-header {
                width: 100%;
                left: 2rem;
                background: none;
                color: #fff;
                padding: 0;
                margin: 0 1rem;
                font-weight: bold;

                span.text {
                    background: $primary;
                    padding: .25rem 1rem;
                }

                .material-icons {
                    display: none;
                }
            }
        }

        .icon .icon-wrapper {
            width: 250px;
            height: 250px;
        }


        header {
            display: flex;
            align-items: center;
            justify-content: flex-end !important;
            width: 50vw;
            max-width: 100vw;
            padding: 1rem 2rem !important;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;

            .logo {
                display: none;
            }


            .container .menu {
                display: none;
            }

            .material-icons {
                display: none;
            }

            nav.main-nav {
                transform: translateX(0);
                flex-direction: row;
                background: none;
                width: auto;
                max-width: 50vw;
                height: fit-content;
                position: static;

                header {
                    display: none;
                }

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    li a {
                        color: #fff;
                        font-size: 18px;
                        opacity: 1;
                        margin: 0 1rem;
                        text-shadow: 0 0 10px #000;
                        display: inline-block;
                        text-align: center;
                        hyphens: none;
                    }

                }
            }


            footer {
                ul.sub-nav {
                    display: none;
                }
            }
        }

        section.header {
            height: 100vh;

            video {
                width: 50vw;
                position: absolute;
                top: 0;
                right: 0;
            }

            section.content {
                padding-right: 7rem;
                height: 100%;


                .row.lg.logo {
                    display: block;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $font-color;
                    align-self: flex-start;
                    flex-grow: 0;

                    img {
                        max-width: max(50%, 150px);
                    }
                }

                .row {
                    flex-direction: column;
                    justify-content: center;
                    flex-grow: 1;
                }

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .container {

                    .row {
                    }

                    .topline, p {
                        font-size: 24px;
                    }

                    h1 {
                        font-size: 55px;
                    }
                }

            }

            div.icon {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        #standorte {
            .karte {
                width: 45%;
                display: block;
                left: 0;
                top: -10%;
                height: 110%;
            }
        }

        footer {
            .marke {
                span {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }

            nav {
                height: 100%;

                ul {
                    height: 100%;
                }

                &.footer-nav {
                    ul {
                        justify-content: flex-start;
                        align-items: center;

                        li {
                            opacity: 1;
                            width: auto;
                            min-width: auto;
                            margin-right: 1rem;

                            a {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.praxis-nav {
                    ul {
                        padding: 0;
                        margin: 0;

                        li {
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }

}
