$primary: #5573AA;
$primary-light: #CDD2E6;
$primary-dark: #3C5A96;

$secondary: #D2A582;
$secondary-light: #F5EEE6;

$danger: #C82846;

$primary-transp: rgba(113, 135, 184, .5);
$font-color: #575756;

$breadcrumb-divider: "\e315";

@import "node_modules/bootstrap/scss/bootstrap";
@import "Pages/dach";
@import "Assets/typography";
@import "Assets/helperClasses";
@import "Assets/header";
@import "Assets/footer";
@import "Assets/reset";
@import "Assets/kontakt";
@import "Components/_components";


html {
    overflow-x: hidden;
}

section {
    padding: 3rem 1rem;
}

nav {
    a {
        transition: color 250ms ease-in-out;
    }

    a:hover {
        color: $secondary;
        text-decoration: none;
    }
}

//Home Header
@include media-breakpoint-up(lg) {
    body.home {
        header {
            @include make-container();
            position: static;
            width: 40%;
            margin-left: 3rem;
            margin-right: 3rem;
        }
    }
}

@media (min-width: 1600px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1500px;
    }
}
