
.condensed {
    font-family: 'brandon-grotesque-cond';
}

.bg-secondary-light {
    background: $secondary-light;
}

.bg-primary-light {
    background: $primary-light;
}

.fullscreen {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.is-square {
    position: relative;
    width: 100%;

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

img.contain {
    object-fit: contain;
}

.topline {
    width: 100%;
    color: $secondary;
    font-weight: 700;
}

.no-break {
    br {
        display: none;
    }
}

//Call to Action
a.c2a {
    background: $primary;
    color: #fff;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem;
    width: 100%;
    display: block;
}

@include media-breakpoint-up(lg) {
    a.c2a {
        width: 300px;
        height: 300px;

        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@include media-breakpoint-down(lg) {
    .sm-container {
        max-width: 720px;
        width: 100%;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;

        .topline {
            padding: 0;
        }

    }
}

@include media-breakpoint-down(md) {
    .sm-container {
        max-width: 540px !important;
    }
}
