.cut-text-wrapper {

    position: relative;
    margin-bottom: 1rem;

    .cut-text {
        height: 100px;
        overflow: hidden;
        margin: 0;
    }

    &.active .cut-text {
        height: fit-content;
    }


    .cut-text-action {
        border: none;
        color: $danger;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: left;
        padding: 2rem 0 1rem 0;
        background: $secondary-light;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $secondary-light 45%);
    }

    .cut-text-action.active {
        position: static;
        background: none;
    }
}

@include media-breakpoint-up(lg) {
    .cut-text-wrapper {
        position: static;

        .cut-text {
            height: fit-content;
        }

        .cut-text-action {
            display: none;
        }
    }
}
