
section.breadcrumps {
    padding-top: 0;
    padding-bottom: 0;

    .breadcrumb {
        background: none;
        padding: 1rem 0;

        .breadcrumb-item, .breadcrumb-item a {
            color: $secondary;
        }

        .breadcrumb-item::before {
            color: $secondary;
            font-family: 'Material Icons';
        }
    }
}
