.news-wrapper {
    .news {
        .news-header {
            font-weight: 700;
        }

        .news-image {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        .news-action {
            a {
                color: $danger;
                display: flex;

                &::before {
                    content: '\e941';
                    font-family: 'Material Icons';
                    display: flex;
                    align-items: center;
                    margin-right: 1rem;
                }
            }
        }

        padding: 2rem 0;
        border-bottom: 1px solid $font-color;

        &:last-child {
            border-bottom: none;
        }
    }

}

.news.slider-controlls {
    display: none;
}

@include media-breakpoint-up(md) {
    .news-wrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: hidden;
        padding: 0;

        .news {
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            flex-wrap: wrap;
            padding: .5rem;
            border: none;
            transition: all 1s ease-in-out;
            width: 50%;

            .news-image {
                min-height: 42vw;
                width: 100%;
                max-width: 100%;
            }

            .news-content {
                padding: .5rem;

                .news-header {
                    text-transform: uppercase;
                    color: $primary;
                }
            }
        }
    }
    .news.slider-controlls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        margin: 1rem auto;

        button.point {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $primary-light;
            border: none;
            margin: 0 .25rem;
            transition: background 1s ease-in-out;


            &.active {
                background: $primary;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .news-wrapper .news {
        width: 25%;

        .news-image {
            min-height: 22vw;
        }
    }
}
