.accordion {
    .list-item .accordion-header {
        cursor: default !important;
    }

    .accordion-item, .list-item {
        margin: 1rem 0;
        border: none;
        border-bottom: 1px solid $secondary;
        background: none;

        .accordion-content {
            display: none;
            padding: 1rem 2rem 2rem 1rem;
        }

        .accordion-header {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            margin: 1rem 0;

            h3 {
                color: $font-color;
                font-size: 18px;

            }

            .accordion-action {
                .icon {
                    transition: all 500ms ease-in-out;
                }
            }

        }

        &.active {

            .accordion-header .accordion-action .icon {
                transform: rotate(180deg);
            }

            .accordion-content {
                display: block;

                a.kontakt {
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 1rem 0;

                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .accordion .accordion-item .accordion-content {
        padding: 1rem 5rem 2rem 1rem;
    }
}

