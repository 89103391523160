
body {
    color: $font-color;
    font-family: "brandon-grotesque", sans-serif;
    hyphens: auto;
    font-size: 100%;
}

@include media-breakpoint-up(lg) {
    body {
        font-size: 24px;
    }
}

a {
    text-decoration: none;
}

h1, h2, h3, h4 {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 700;
    color: $primary;
    text-transform: uppercase;

    .subline {
        display: block;
        font-weight: 400;
        text-transform: uppercase;
        color: $primary;
        font-size: 1em;
    }

}

br.lg {
    display: none;
}

@include media-breakpoint-up(lg) {
    h1 {
        font-size: 2.2em;
    }
    .topline {
        font-size: 1em;
    }
}


@include media-breakpoint-up(xxl) {

    br.lg {
        display: inline;
    }
}


