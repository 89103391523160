.partner-icon {
    display: flex;
    justify-content: center;

    img {
        height: 100%;
        box-shadow: #00000029 0 0 50px;
        border-radius: 50%;
        width: 100%;
        max-width: 150px;
    }
}

@include media-breakpoint-up(md) {
    .partner-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
