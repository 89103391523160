.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    background: rgba(255, 255, 255, .5);

    .close {
        position: absolute;
        left: 10%;
        top: 5%;
        background: #5573AA;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
        cursor: pointer;
        color: #fff;
        padding: 0.5rem;
        border-radius: 10px;
        text-transform: uppercase;
        font-size: 18px;
        display: flex;
    }

}

.aerzte .video {
    .play-button {
        position: absolute;
        width: 80px;
        object-fit: contain;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
        z-index: 80;
        cursor: pointer;
    }
}
