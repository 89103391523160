.popup {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  display: none;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-content {
    padding: 4rem 3rem;
    background: #fff;
    border-radius: 1rem;

    .popup-header {
      display: flex;
      justify-content: space-between;

      .close {
        cursor: pointer;
      }
    }

  }
}