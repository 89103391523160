.container.kontaktdaten {
    font-size: 18px;

    p {
        margin-bottom: .5rem;
    }

}

input {
    outline: none;
    border: none
}

textarea {
    outline: none;
    border: none
}

form {
    h3 {
        width: 100%;
    }

    .material-icons {
        margin-right: 1rem;
    }
}

textarea:focus, input:focus {
    border-color: transparent !important
}

input:focus::-webkit-input-placeholder {
    color: transparent
}

input:focus:-moz-placeholder {
    color: transparent
}

input:focus::-moz-placeholder {
    color: transparent
}

input:focus:-ms-input-placeholder {
    color: transparent
}

textarea:focus::-webkit-input-placeholder {
    color: transparent
}

textarea:focus:-moz-placeholder {
    color: transparent
}

textarea:focus::-moz-placeholder {
    color: transparent
}

textarea:focus:-ms-input-placeholder {
    color: transparent
}

input::-webkit-input-placeholder {;
    color: #555
}

input:-moz-placeholder {;
    color: #555
}

input::-moz-placeholder {;
    color: #555
}

input:-ms-input-placeholder {;
    color: #555
}

textarea::-webkit-input-placeholder {;
    color: #555
}

textarea:-moz-placeholder {;
    color: #555
}

textarea::-moz-placeholder {;
    color: #555
}

textarea:-ms-input-placeholder {;
    color: #555
}

button {
    outline: none !important;
    border: none;
    background: 0 0
}

button:hover {
    cursor: pointer
}

iframe {
    border: none !important
}


.contact100-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 68px
}

.contact100-form-title {
    display: block;
    width: 100%;
    color: #333;
    line-height: 1.2;
    text-align: left;
    padding-bottom: 44px
}

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 13px;
    margin-bottom: 65px
}

.rs1-wrap-input100 {
    width: calc((100% - 30px) / 2)
}

.label-input100 {

    font-size: 15px;
    color: #999;
    line-height: 1.5;
    padding-left: 5px
}

.input100 {
    display: block;
    width: 100%;
    background: 0 0;

    font-size: 18px;
    color: #555;
    line-height: 1.2;
    padding: 0 5px
}

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none
}

.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    background: $primary;
}

input.input100 {
    height: 40px
}

textarea.input100 {
    min-height: 110px;
    padding-top: 9px;
    padding-bottom: 13px
}

.input100:focus + .focus-input100::before {
    width: 100%
}

.has-val.input100 + .focus-input100::before {
    width: 100%
}

.container-contact100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    margin-top: -25px
}

.contact100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 160px;
    height: 50px;
    background-color: $primary;
    border-radius: 25px;;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    box-shadow: 0 10px 30px 0 $danger;
    -moz-box-shadow: 0 10px 30px 0 $danger;
    -webkit-box-shadow: 0 10px 30px 0 $danger;
    -o-box-shadow: 0 10px 30px 0 $danger;
    -ms-box-shadow: 0 10px 30px 0 $danger;
}


.contact100-form-btn:hover {
    background-color: #333;
    box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
    -moz-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
    -webkit-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
    -o-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
    -ms-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5)
}

@media(max-width: 992px) {
    .wrap-contact100 {
        padding: 82px 80px 33px
    }
}

@media(max-width: 768px) {
    .rs1-wrap-input100 {
        width: 100%
    }
}

@media(max-width: 576px) {
    .wrap-contact100 {
        padding: 82px 15px 33px
    }
}

.validate-input {
    position: relative
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 58%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 2px;
    pointer-events: none;;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    -moz-transition: opacity .4s;
    transition: opacity .4s
}


.alert-validate:hover:before {
    visibility: visible;
    opacity: 1
}

@media(max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1
    }
}

.contact100-more {

    font-size: 14px;
    color: #999;
    line-height: 1.5
}

.contact100-more-highlight {
    color: #ff4b5a
}

.contact100-btn-hide {
    color: #333;
    font-size: 14px;
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: 50%;
    top: -15px;
    right: -15px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.contact100-btn-hide:hover {
    background-color: #333;
    color: #fff
}

.contact100-btn-show {
    color: #fff;
    font-size: 39px;
    position: fixed;
    z-index: -1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.contact100-btn-show::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    background-color: #ff4b5a;
    border-radius: 50%;
    top: 0;
    left: 0;
    box-shadow: 0 10px 30px 0 rgba(255, 75, 90, .5);
    -moz-box-shadow: 0 10px 30px 0 rgba(255, 75, 90, .5);
    -webkit-box-shadow: 0 10px 30px 0 rgba(255, 75, 90, .5);
    -o-box-shadow: 0 10px 30px 0 rgba(255, 75, 90, .5);
    -ms-box-shadow: 0 10px 30px 0 rgba(255, 75, 90, .5);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    -webkit-animation: beatbtn .9s ease-in-out infinite;
    animation: beatbtn .9s ease-in-out infinite
}

@keyframes beatbtn {
    0% {
        background-color: #ff4b5a;
        transform-origin: center;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    50% {
        background-color: rgba(255, 75, 90, .8);
        transform-origin: center;
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
}
