.list-wrapper {
    margin: 3rem 0 1rem 0;

    .list-heading {
        text-transform: uppercase;
    }

    .list {
        list-style-type: none;

        .list-item {
            a {
                display: block;
                padding: 1rem 0;
                border-bottom: 1px solid #fff;
                text-transform: uppercase;
                font-weight: 700;
                color: $primary;
            }

            a::after {
                content: '\e941';
                font-family: 'Material Icons';
                display: inline;
                float: right;
                justify-content: space-between;
                color: $danger;
            }
        }

        &.black {
            .list-item a {
                color: $font-color;
            }
        }
    }
}
