footer {
    padding-bottom: 3rem;
    padding-top: 2rem;

    .container {
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $primary-dark;


    }

    .top {
        color: $primary;
        background: #DEE1EF;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        width: 66px;
        height: 66px;
        top: -33px;
        border-radius: 50%;
        cursor: pointer;

        .icon {
            transform: translate(-50%, -50%);
            position: relative;
            top: 50%;
            left: 50%;
        }
    }

    //.navigation, .logo, .partner {
    //    padding: 1rem 2rem;
    //}

    .navigation {


        nav {

            ul.main {
                display: none;
            }

            ul {
                list-style-type: none;
                text-transform: uppercase;
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
                align-items: center;

                li {
                    margin: .5rem 1rem;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 50%;
        }
    }

    .partner {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        p {
            text-transform: uppercase;
            margin-right: 1rem;
            margin-bottom: 0;
        }

        img {
            max-width: 50%;
            max-height: 150px;
            object-fit: contain;
        }
    }

    .praxis-nav {
        ul {
            padding-bottom: 2rem;

            li a {
                display: block;
                padding: .5rem 0;
            }
        }
    }

    .footer-nav {
        ul {
            padding: 1rem 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                min-width: 30%;
                margin: 1rem 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    footer {
        display: flex;
        flex-wrap: wrap;

        .navigation {
            width: 100%;
        }

        .logo, .partner {
            width: 50%;
        }
    }
}

@include media-breakpoint-up(lg) {
    footer {
        .navigation {
            .active-item-dot {
                display: none;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $secondary;
                transform: translateX(-50%);
                left: 50%;
                top: -15px;
            }

            nav {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                ul li.active {
                    position: relative;

                    .active-item-dot {
                        position: absolute;
                        display: block;
                    }

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    a {
                        opacity: 1;
                    }
                }

                ul.main {
                    width: 60%;
                    display: flex;
                }

                ul li {
                    margin: .25rem;

                    a {
                        opacity: .5;
                    }
                }

                ul.sub-nav li a {
                    font-size: .8rem;
                }
            }
        }
    }
}
