.main-nav section.kontakt {
    &.closed {
        transform: translateY(200vh);
    }

    &:hover {
        background: none;
    }

    transition: all 500ms ease-in-out;
    cursor: initial;
    position: fixed;
    bottom: 2rem;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    background: none;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    .wrapper {
        width: 100vw;
        height: fit-content;
        max-height: 95vh;
        position: fixed;
        bottom: 0;
        padding-bottom: 2rem;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: end;
        color: $font-color;
        font-size: 18px;

        a {
            color: $danger;
        }

        .adresse, .oeffnungszeiten, .data, .links, .partner {
            padding: 0.5rem 2rem;
        }

        .close {
            cursor: pointer;
            font-weight: 400;
            opacity: 1;
            width: 100%;
            background: $danger;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            .material-icons {
                margin: 1rem;
            }
        }

        .adresse {
            p {
                margin-bottom: 0;
            }
        }

        .data {
            strong {
                display: block;
            }
        }

        .links {
            display: flex;
            justify-content: space-between;

            a.link {
                text-transform: uppercase;
                font-weight: bold;
                border-bottom: 1px solid $danger;

                &:hover {
                    text-decoration: none;
                }

                &:before {
                    content: '\f1df';
                    font-family: "Material Icons";
                    margin-right: 1rem;
                }

            }
        }

        .partner {
            border-top: 1px solid $danger;
            align-items: center;

            strong {
                display: block;
                margin: 1rem 0;
            }

            img {
                width: 50%;

                &:first-child {
                    padding-right: 1rem;
                }

                &:last-child {
                    width: 42%;
                    padding-left: 1rem;
                }
            }
        }
    }
}
