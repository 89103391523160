.tile-holder {
    .tile {
        display: flex;
        align-items: stretch;
        justify-content: center;
        cursor: pointer;

        .tile-wrapper {

            background: $primary-light;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $primary;
                transition: all 500ms ease-in-out;
            }

            padding: 1rem 3rem;
            margin: 1rem 0;
            width: 100%;

            .tile-icon {
                margin: 1rem 0;

                svg {
                    margin: 1rem;
                    width: 130px;
                    fill: $primary;
                    transition: all 1s ease-in-out;

                    .st0 {
                        fill: $secondary-light;
                        transition: all 1s ease-in-out;
                    }

                    .sta1 {
                        fill: $primary;
                    }
                }
            }

            .tile-text {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 1em;
                transition: all 1s ease-in-out;
            }
        }

        &:hover {
            .tile-icon svg {
                fill: #fff;

                .st0 {
                    fill: $secondary;
                }
            }

            .tile-wrapper {
                background: $primary;

                .tile-text {
                    color: #fff;
                }
            }
        }
    }

}

@include media-breakpoint-up(md) {
    .tile-holder {

        .tile {
            .tile-wrapper {
                padding: 0;

                a {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    padding: 1rem;
                }

                .tile-icon {
                    margin: 0;

                    svg {
                        height: auto;
                        margin: 0;
                        padding: .5rem;
                        width: 200px;
                    }
                }

                a {
                    text-align: center;
                    font-size: 1em;
                }

                .tile-text {
                    padding: 1rem;
                }
            }
        }
    }
}
